<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <div v-if="isList" class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <h5 class="card-title">{{ pageTitle }} List</h5>
            </div>
            <div class="col-md-7">
              <div class="row justify-content-end">
                <div class="col-4">
                  <VSelect
                    v-model="filterStatus"
                    placeholder="-- All Status --"
                    :options="mrStatus"
                    :reduce="(v) => v.status"
                    label="status_description"
                  >
                  </VSelect>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-info" type="button" @click="doReset">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row mt-4">
            <div class="table-responsive">
              <table class="table product-overview table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th style="max-width: 100px">Category Order Level</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
                  <tr v-for="(v,k) in data.data" :key="k">
                    <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                    <td>{{ v.order_num }}</td>
                    <td>
                      <a :href="v.url" target="_blank">{{ v.name }}</a>
                    </td>
                    <td>
                      <LabelStatus :status="v[statusKey]" />
                    </td>
                    <td>
                      <router-link 
                        v-if="moduleRole('Edit')"
                        :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                        class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                        <i class="ti-marker-alt"></i>
                      </router-link> 
                      <a 
                        v-if="moduleRole('Edit')"
                        href="javascript:;" 
                        class="text-inverse icon_action act_icon mx-1" v-tooltip="'Change Status'"
                        @click="doChangeStatus(k,v)">
                        <i class="ti-settings"></i>
                      </a> 
                      <a 
                        v-if="moduleRole('Delete')"
                        href="javascript:;" 
                        class="text-inverse icon_action act_icon" 
                        v-tooltip="'Delete'"
                        @click="doDelete(k,v)">
                        <i class="ti-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="notFound"><td colspan="99" class="text-center py-4"><h4>{{notFound}}</h4></td></tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <div
                class="pagination-flat float-right"
                v-if="data.data !== false && data.data.length"
              >
                <Pagination
                  :data="data"
                  :limit="2"
                  @pagination-change-page="doPaginate"
                >
                  <span slot="prev-nav">Previous</span>
                  <span slot="next-nav">Next</span>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">
            {{ isAdd ? "Add" : "Edit" }} {{ pageTitle }}
          </h5>

          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doSubmitCRUD)">
              <b-row>
                <b-col md="12">
                  <b-row>
                    <b-col md="6">
                      <BoField
                        label="Name"
                        placeholder="Platinum / Gold / etc"
                        v-model="row.name"
                        :rules="mrValidation.name"
                      />
                    </b-col>
                    <b-col md="6">
                      <BoTextarea
                        label="Description"
                        placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                        v-model="row.description"
                        :mandatory="false"
                      />
                    </b-col>
                    <b-col md="2" class="mr-4">
                      <label for="">Category Order Level<span class="text-danger">*</span></label>
                      <input type="number" v-model="row.order_num" class="form-control" placeholder="e.g. 1">
                      <VValidate
                        name="Category Order Level"
                        v-model="row.order_num"
                        :rules="mrValidation.order_num"
                      />
                    </b-col>
                    <b-co md="4">
                      <div class="form-group">
                        <label class="control-label">
                          Status
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div>
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="Y"
                          />
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="N"
                          />
                        </div>
                        <VValidate
                          name="Status"
                          v-model="row[statusKey]"
                          :rules="mrValidation[statusKey]"
                        />
                      </div>
                    </b-co>
                  </b-row>
                </b-col>
              </b-row>
              <div class="row mt-4">
                <div class="col-md-12 text-right">
                  <router-link
                    :to="{ name: $route.name }"
                    class="btn btn-rounded btn-light mr-2"
                    >Cancel</router-link
                  >
                  <button type="submit" class="btn btn-rounded btn-info">
                    {{ isAdd ? "Submit" : "Update" }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import { extend } from "vee-validate";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
  },
  data() {
    return {
      idKey: "id",
      statusKey: "is_active",
      mrStatus: [],
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>